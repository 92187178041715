.desktop-client {
  display: block;
}
.mobile-client {
  display: none;
}

.client-buttons {
  padding-left: 43%;
  padding-right: 20%;
}

.client-btn-left {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid grey;
  padding: 10%;
}

.client-btn-right {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid grey;
  padding: 10%;
}

.image-1-client {
  margin-top: 20% !important;
  padding-left: 5% !important;
  z-index: 1000;
  position: relative;
}
.image-2-client {
  margin-top: 18% !important;
  z-index: 1000;
  padding-left: 8% !important;
  position: relative;
}
.image-3-client {
  z-index: 10;
  position: relative;
  margin-left: 20% !important;
  margin-top: 5% !important;
}
.image-4-client {
  margin-top: 10% !important;
  padding-left: 10% !important;
}
.image-5-client {
  z-index: 10;
  position: relative;
  margin-top: -2% !important;
  margin-left: 10% !important;
}
.image-6-client {
  margin-top: 8% !important;
}
.client-image-box {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 10%;
  margin-top: -8%;
}

.clientimage{
  margin: auto;
  display: block;
}

.client-image-top{
  padding-top: 10%;
}

@media only screen and (max-width: 5061px) {
  .image-1-client {
    margin-top: 13% !important;
    padding-left: 15% !important;
    z-index: 1000;
    position: relative;
  }
  .image-2-client {
    margin-top: 15% !important;
    z-index: 1000;
    padding-left: 0% !important;
    margin-left: -20% !important;
    position: relative;
  }
  .image-3-client {
    z-index: 10;
    position: relative;
    margin-left: 16% !important;
    margin-top: 0% !important;
  }
  .image-4-client {
    margin-top: 2% !important;
    padding-left: 0% !important;
    margin-left: -17% !important;
  }
  .image-5-client {
  
    z-index: 10;
    position: relative;
    margin-top: 0% !important;
    margin-left: 13% !important;

  }
  .image-6-client {
    margin-top: 2% !important;
    margin-left: -25% !important;
   
  }
  .client-image-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10%;
    margin-top: -4%;
  }
}
@media only screen and (max-width: 3795px) {
  .image-1-client {
    margin-top: 13% !important;
    padding-left: 15% !important;
    z-index: 1000;
    position: relative;
  }
  .image-2-client {
    margin-top: 18% !important;
    z-index: 1000;
    padding-left: 0% !important;
    margin-left: -15% !important;
    position: relative;
  }
  .image-3-client {
    z-index: 10;
    position: relative;
    margin-left: 16% !important;
    margin-top: 0% !important;
  }
  .image-4-client {
    margin-top: 2% !important;
    padding-left: 0% !important;
    margin-left: -13% !important;
  }
  .image-5-client {
  
    z-index: 10;
    position: relative;
    margin-top: 0% !important;
    margin-left: 13% !important;

  }
  .image-6-client {
    margin-top: 2% !important;
    margin-left: -20% !important;
   
  }
  .client-image-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10%;
    margin-top: -4%;
  }
}
@media only screen and (max-width: 3751px) {
  .image-1-client {
    margin-top: 13% !important;
    padding-left: 15% !important;
    z-index: 1000;
    position: relative;
  }
  .image-2-client {
    margin-top: 18% !important;
    z-index: 1000;
    padding-left: 0% !important;
    margin-left: -15% !important;
    position: relative;
  }
  .image-3-client {
    z-index: 10;
    position: relative;
    margin-left: 16% !important;
    margin-top: 0% !important;
  }
  .image-4-client {
    margin-top: 2% !important;
    padding-left: 0% !important;
    margin-left: -13% !important;
  }
  .image-5-client {
  
    z-index: 10;
    position: relative;
    margin-top: 0% !important;
    margin-left: 13% !important;

  }
  .image-6-client {
    margin-top: 2% !important;
    margin-left: -20% !important;
   
  }
  .client-image-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10%;
    margin-top: -4%;
  }
}
@media only screen and (max-width: 2791px) {
  .image-1-client {
    margin-top: 13% !important;
    padding-left: 15% !important;
    z-index: 1000;
    position: relative;
  }
  .image-2-client {
    margin-top: 18% !important;
    z-index: 1000;
    padding-left: 0% !important;
    margin-left: -10% !important;
    position: relative;
  }
  .image-3-client {
    z-index: 10;
    position: relative;
    margin-left: 16% !important;
    margin-top: 0% !important;
  }
  .image-4-client {
    margin-top: 5% !important;
    padding-left: 0% !important;
    margin-left: -3% !important;
  }
  .image-5-client {
    z-index: 10;
    position: relative;
    margin-top: -2% !important;
    margin-left: 10% !important;
  }
  .image-6-client {
    margin-top: 5% !important;
    margin-left: -20% !important;
  }
  .client-image-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10%;
    margin-top: -6%;
  }
}

@media only screen and (max-width: 2335px) {
  .image-1-client {
    margin-top: 13% !important;
    padding-left: 15% !important;
    z-index: 1000;
    position: relative;
  }
  .image-2-client {
    margin-top: 18% !important;
    z-index: 1000;
    padding-left: 0% !important;
    margin-left: -10% !important;
    position: relative;
  }
  .image-3-client {
    z-index: 10;
    position: relative;
    margin-left: 16% !important;
    margin-top: 0% !important;
  }
  .image-4-client {
    margin-top: 5% !important;
    padding-left: 0% !important;
    margin-left: -3% !important;
  }
  .image-5-client {
    z-index: 10;
    position: relative;
    margin-top: -2% !important;
    margin-left: 10% !important;
  }
  .image-6-client {
    margin-top: 7% !important;
    margin-left: -20% !important;
  }
  .client-image-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10%;
    margin-top: -6%;
  }
}

@media only screen and (max-width: 2105px) {
  .image-1-client {
    margin-top: 13% !important;
    padding-left: 15% !important;
    z-index: 1000;
    position: relative;
  }
  .image-2-client {
    margin-top: 18% !important;
    z-index: 1000;
    padding-left: 0% !important;
    margin-left: -10% !important;
    position: relative;
  }
  .image-3-client {
    z-index: 10;
    position: relative;
    margin-left: 16% !important;
    margin-top: 0% !important;
  }
  .image-4-client {
    margin-top: 5% !important;
    padding-left: 0% !important;
    margin-left: -3% !important;
  }
  .image-5-client {
    z-index: 10;
    position: relative;
    margin-top: -2% !important;
    margin-left: 10% !important;
  }
  .image-6-client {
    margin-top: 8% !important;
    margin-left: -20% !important;
  }
  .client-image-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10%;
    margin-top: -6%;
  }
}

@media only screen and (max-width: 1947px) {
  .image-1-client {
    margin-top: 13% !important;
    padding-left: 15% !important;
    z-index: 1000;
    position: relative;
  }
  .image-2-client {
    margin-top: 18% !important;
    z-index: 1000;
    padding-left: 0% !important;
    margin-left: -5% !important;
    position: relative;
  }
  .image-3-client {
    z-index: 10;
    position: relative;
    margin-left: 16% !important;
    margin-top: 0% !important;
  }
  .image-4-client {
    margin-top: 5% !important;
    padding-left: 0% !important;
    margin-left: 0% !important;
  }
  .image-5-client {
    z-index: 10;
    position: relative;
    margin-top: -2% !important;
    margin-left: 10% !important;
  }
  .image-6-client {
    margin-top: 10% !important;
    margin-left: -20% !important;
  }
  .client-image-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10%;
    margin-top: -6%;
  }
}

@media only screen and (max-width: 1771px) {
  .image-1-client {
    margin-top: 13% !important;
    padding-left: 15% !important;
    z-index: 1000;
    position: relative;
  }
  .image-2-client {
    margin-top: 18% !important;
    z-index: 1000;
    padding-left: 0% !important;
    margin-left: 0% !important;
    position: relative;
  }
  .image-3-client {
    z-index: 10;
    position: relative;
    margin-left: 20% !important;
    margin-top: 0% !important;
  }
  .image-4-client {
    margin-top: 5% !important;
    padding-left: 0% !important;
    margin-left: 0% !important;
  }
  .image-5-client {
    z-index: 10;
    position: relative;
    margin-top: -2% !important;
    margin-left: 10% !important;
  }
  .image-6-client {
    margin-top: 10% !important;
    margin-left: -5% !important;
  }
  .client-image-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10%;
    margin-top: -6%;
  }
}

@media only screen and (max-width: 1604px) {
  .image-1-client {
    margin-top: 20% !important;
    padding-left: 15% !important;
    z-index: 1000;
    position: relative;
  }
  .image-2-client {
    margin-top: 22% !important;
    z-index: 1000;
    padding-left: 0% !important;
    margin-left: 0% !important;
    position: relative;
  }
  .image-3-client {
    z-index: 10;
    position: relative;
    margin-left: 10% !important;
    margin-top: 5% !important;
  }
  .image-4-client {
    margin-top: 10% !important;
    padding-left: 0% !important;
    margin-left: 10% !important;
  }
  .image-5-client {
    z-index: 10;
    position: relative;
    margin-top: -2% !important;
    margin-left: 20% !important;
  }
  .image-6-client {
    margin-top: 10% !important;
    margin-left: -15% !important;
  }
  .client-image-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10%;
    margin-top: -8%;
  }
}

@media only screen and (max-width: 1439px) {
  .image-1-client {
    margin-top: 20% !important;
    padding-left: 15% !important;
    z-index: 1000;
    position: relative;
  }
  .image-2-client {
    margin-top: 22% !important;
    z-index: 1000;
    padding-left: 0% !important;
    margin-left: 0% !important;
    position: relative;
  }
  .image-3-client {
    z-index: 10;
    position: relative;
    margin-left: 10% !important;
    margin-top: 5% !important;
  }
  .image-4-client {
    margin-top: 10% !important;
    padding-left: 0% !important;
    margin-left: 15% !important;
  }
  .image-5-client {
    z-index: 10;
    position: relative;
    margin-top: -2% !important;
    margin-left: 20% !important;
  }
  .image-6-client {
    margin-top: 10% !important;
    margin-left: -15% !important;
  }
  .client-image-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10%;
    margin-top: -8%;
  }
}

@media only screen and (max-width: 1273px) {
  .image-1-client {
    margin-top: 20% !important;
    padding-left: 15% !important;
    z-index: 1000;
    position: relative;
  }
  .image-2-client {
    margin-top: 22% !important;
    z-index: 1000;
    padding-left: 0% !important;
    margin-left: 0% !important;
    position: relative;
  }
  .image-3-client {
    z-index: 10;
    position: relative;
    margin-left: 10% !important;
    margin-top: 5% !important;
  }
  .image-4-client {
    margin-top: 10% !important;
    padding-left: 0% !important;
    margin-left: 15% !important;
  }
  .image-5-client {
    z-index: 10;
    position: relative;
    margin-top: -2% !important;
    margin-left: 20% !important;
  }
  .image-6-client {
    margin-top: 15% !important;
    margin-left: -15% !important;
  }
  .client-image-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10%;
    margin-top: -8%;
  }
}

@media only screen and (max-width: 1187px) {
  .image-1-client {
    margin-top: 20% !important;
    padding-left: 10% !important;
    z-index: 1000;
    position: relative;
  }
  .image-2-client {
    margin-top: 22% !important;
    z-index: 1000;
    padding-left: 10% !important;
    margin-left: 0% !important;
    position: relative;
  }
  .image-3-client {
    z-index: 10;
    position: relative;
    margin-left: 10% !important;
    margin-top: 5% !important;
  }
  .image-4-client {
    margin-top: 10% !important;
    padding-left: 10% !important;
    margin-left: 15% !important;
  }
  .image-5-client {
    z-index: 10;
    position: relative;
    margin-top: 2% !important;
    margin-left: 20% !important;
  }
  .image-6-client {
    margin-top: 15% !important;
    margin-left: -15% !important;
  }
  .client-image-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10%;
    margin-top: -8%;
  }
}

@media only screen and (max-width: 1056px) {
  .image-1-client {
    margin-top: 20% !important;
    padding-left: 10% !important;
    z-index: 1000;
    position: relative;
  }
  .image-2-client {
    margin-top: 22% !important;
    z-index: 1000;
    padding-left: 10% !important;
    margin-left: 0% !important;
    position: relative;
  }
  .image-3-client {
    z-index: 10;
    position: relative;
    margin-left: 10% !important;
    margin-top: 5% !important;
  }
  .image-4-client {
    margin-top: 13% !important;
    padding-left: 10% !important;
    margin-left: 15% !important;
  }
  .image-5-client {
    z-index: 10;
    position: relative;
    margin-top: 2% !important;
    margin-left: 20% !important;
  }
  .image-6-client {
    margin-top: 18% !important;
    margin-left: -15% !important;
  }
  .client-image-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10%;
    margin-top: -8%;
  }
}

@media only screen and (max-width: 1000px) {
  .desktop-client {
    display: none;
  }
  .mobile-client {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .image-1-client {
    margin-top: 20% !important;
    padding-left: 10% !important;
    z-index: 1000;
    position: relative;
  }
  .clientimage{
    width:  90% !important;
    
  }
  .image-2-client {
    margin-top: 22% !important;
    z-index: 1000;
    padding-left: 10% !important;
    margin-left: 0% !important;
    position: relative;
  }
  .image-3-client {
    z-index: 10;
    position: relative;
    margin-left: 10% !important;
    margin-top: 5% !important;
  }
  .image-4-client {
    margin-top: 13% !important;
    padding-left: 10% !important;
    margin-left: 15% !important;
  }
  .image-5-client {
    z-index: 10;
    position: relative;
    margin-top: 2% !important;
    margin-left: 20% !important;
  }
  .image-6-client {
    margin-top: 18% !important;
    margin-left: -15% !important;
  }
  .client-image-box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10%;
    margin-top: -8%;
  }
}

@media only screen and (max-width: 442px) {
  .desktop-client {
    display: none;
  }
  .mobile-client {
    display: block;
  }
  .client-btn-right {
    width: 40px;
    height: 40px;
    margin-left: 5%;
    padding: 8%;
  }
  .client-btn-left {
    width: 40px;
    height: 40px;
    margin-left: -5%;
    padding: 8%;
  }
}

@media only screen and (max-width: 330px) {
  .desktop-client {
    display: none;
  }
  .mobile-client {
    display: block;
  }
  .client-btn-right {
    display: none;
  }
  .client-btn-left {
    display: none;
  }
}
