.banner{
    .banner {
        position: relative;
        height: 300px; /* Adjust height as needed */
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        
       
      }
      
      .blur-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(102, 102, 228, 0.5); /* Blue overlay color with transparency */
        backdrop-filter: blur(2px); 
      }
      
      .banner-content {
        position: absolute;
        bottom: 20px;
        left: 20px;
        color: white;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
      }
}


/* .fade-enter {
  opacity: 0;
  transform: translateY(20px);
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
} */


/* .fade-enter {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
} */
/* 
.fade-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms; 
} */

.ml2 {
  font-weight: 900;
  font-size: 3.5em;
}

.ml2 .letter {
  display: inline-block;
  line-height: 1em;
}