.mobile-login {
  padding: 5%;
  display: none;
}

.desktop-login {
  display: block;
}

@media only screen and (max-width: 670px) {
  .mobile-login {
    padding: 5%;
    display: block;
  }

  .desktop-login {
    display: none;
  }
}
