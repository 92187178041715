.mobile-create-order {
  display: none;
}
.desktop-create-order {
  display: block;
}
.complete-address {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  background: #fff;
}
.desktop-order-success {
  display: block;
}
.mobile-order-success{
  display: none;
}

@media only screen and (max-width: 670px) {
  .mobile-create-order {
    display: block;
  }
  .desktop-create-order {
    padding: 5%;
    display: none;
  }
  .desktop-order-success {
    display: none;
  }
  .mobile-order-success{
    display: block;
  }
}
