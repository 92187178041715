._1A98vVyC {
  margin-top: 0%;
  background-color: rgb(250, 250, 252);
}

.services-section {
  margin-top: 15%;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
  clip-path: polygon(
    0 15%,
    15% 0,
    100% 0,
    100% 0%,
    100% 100%,
    91% 100%,
    0% 100%,
    0% 100%,
    0% 0%
  );
  max-width: 50% !important;
}

.story-close-btn {
  float: right;
  margin-top: -9% !important;
}

.story-text {
  margin: 0;
  font-weight: 400;
  font-size: 1.3rem !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding: 2%;
  text-align: center;
  font-family: auto;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
    clip-path: polygon(
      0 13%,
      15% 0,
      100% 0,
      100% 0%,
      100% 100%,
      91% 100%,
      0% 100%,
      0% 100%,
      0% 0%
    );
  }
}
@media only screen and (max-width: 890px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
    clip-path: polygon(
      0 13%,
      15% 0,
      100% 0,
      100% 0%,
      100% 100%,
      91% 100%,
      0% 100%,
      0% 100%,
      0% 0%
    );
  }
}
@media only screen and (max-width: 833px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
    clip-path: polygon(
      0 14%,
      15% 0,
      100% 0,
      100% 0%,
      100% 100%,
      91% 100%,
      0% 100%,
      0% 100%,
      0% 0%
    );
  }
  .story-text {
    font-size:  0.8rem !important;
  }
  .close-story-icon{
    font-size: 1rem !important;
    margin-top: -10% !important;
  }
}
@media only screen and (max-width: 800px) {
  ._1A98vVyC {
    background-color: rgb(250, 250, 252);
  }
}
@media only screen and (max-width: 700px) {
  ._1A98vVyC {
    background-color: rgb(250, 250, 252);
  }
}
@media only screen and (max-width: 695px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
    clip-path: polygon(
      0 14%,
      15% 0,
      100% 0,
      100% 0%,
      100% 100%,
      91% 100%,
      0% 100%,
      0% 100%,
      0% 0%
    );
  }
  .read-more-story-btn{
    font-size:  0.5rem !important;
  }
}
@media only screen and (max-width: 617px) {
  ._1A98vVyC {
    background-color: rgb(250, 250, 252);
  }
}
@media only screen and (max-width: 594px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
    clip-path: polygon(
      0 14%,
      15% 0,
      100% 0,
      100% 0%,
      100% 100%,
      91% 100%,
      0% 100%,
      0% 100%,
      0% 0%
    );
  }
}

@media only screen and (max-width: 557px) {
  ._1A98vVyC {
    background-color: rgb(250, 250, 252);
  }
}
@media only screen and (max-width: 500px) {
  ._1A98vVyC {
    background-color: rgb(250, 250, 252);
  }
  .story-close-btn {
    margin-top: -10% !important;
  }
}
@media only screen and (max-width: 482px) {
  ._1A98vVyC {
    background-color: rgb(250, 250, 252);
  }
  .story-close-btn {
    display:  none !important;
  }
}

@media only screen and (max-width: 473px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
    clip-path: polygon(
      0 13%,
      15% 0,
      100% 0,
      100% 0%,
      100% 100%,
      91% 100%,
      0% 100%,
      0% 100%,
      0% 0%
    );
  }
  .story-close-btn {
    margin-top: -12% !important;
  }
}
@media only screen and (max-width: 425px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
    clip-path: polygon(
      0 13%,
      15% 0,
      100% 0,
      100% 0%,
      100% 100%,
      91% 100%,
      0% 100%,
      0% 100%,
      0% 0%
    );
  }
  .story-close-btn {
    margin-top: -12% !important;
  }
}

@media only screen and (max-width: 376px) {
  .story-close-btn {
    margin-top: -12% !important;
  }
}

@media only screen and (max-width: 339px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
    clip-path: polygon(
      0 14%,
      15% 0,
      100% 0,
      100% 0%,
      100% 100%,
      91% 100%,
      0% 100%,
      0% 100%,
      0% 0%
    );
  }
}
@media only screen and (max-width: 332px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
    clip-path: polygon(
      0 7%,
      15% 0,
      100% 0,
      100% 0%,
      100% 100%,
      91% 100%,
      0% 100%,
      0% 100%,
      0% 0%
    );
  }
}

@media only screen and (max-width: 5061px) {
  .services-section {
    margin-top: 16%;
  }
}
@media only screen and (max-width: 4500px) {
  .services-section {
    margin-top: 15%;
  }
}

@media only screen and (max-width: 3508px) {
  .services-section {
    margin-top: 15%;
  }
}
@media only screen and (max-width: 3315px) {
  .services-section {
    margin-top: 14.5%;
  }
}
@media only screen and (max-width: 3186px) {
  .services-section {
    margin-top: 14%;
  }
}
@media only screen and (max-width: 2800px) {
  .services-section {
    margin-top: 13.5%;
  }
}
@media only screen and (max-width: 2791px) {
  .services-section {
    margin-top: 14.5%;
  }
}
@media only screen and (max-width: 2728px) {
  .services-section {
    margin-top: 14.5%;
  }
}
@media only screen and (max-width: 2665px) {
  .services-section {
    margin-top: 14.5%;
  }
}
@media only screen and (max-width: 2507px) {
  .services-section {
    margin-top: 14.4%;
  }
}
@media only screen and (max-width: 2492px) {
  .services-section {
    margin-top: 14%;
  }
}
@media only screen and (max-width: 2386px) {
  .services-section {
    margin-top: 14%;
  }
}
@media only screen and (max-width: 2196px) {
  .services-section {
    margin-top: 14.6%;
  }
}
@media only screen and (max-width: 2179px) {
  .services-section {
    margin-top: 14%;
  }
}
@media only screen and (max-width: 2113px) {
  .services-section {
    margin-top: 14.4%;
  }
}
@media only screen and (max-width: 2109px) {
  .services-section {
    margin-top: 14.4%;
  }
}
@media only screen and (max-width: 2077px) {
  .services-section {
    margin-top: 13.5%;
  }
}
@media only screen and (max-width: 1991px) {
  .services-section {
    margin-top: 14.8%;
  }
}
@media only screen and (max-width: 1977px) {
  .services-section {
    margin-top: 14.5%;
  }
}
@media only screen and (max-width: 1968px) {
  .services-section {
    margin-top: 14.5%;
  }
}
@media only screen and (max-width: 1958px) {
  .services-section {
    margin-top: 14.5%;
  }
}
@media only screen and (max-width: 1900px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1764px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1699px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1692px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1639px) {
  .services-section {
    margin-top: 14%;
  }
}
@media only screen and (max-width: 1632px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1526px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1517px) {
  .services-section {
    margin-top: 14%;
  }
}
@media only screen and (max-width: 1488px) {
  .services-section {
    margin-top: 13.6%;
  }
}
@media only screen and (max-width: 1429px) {
  .services-section {
    margin-top: 13.6%;
  }
}
@media only screen and (max-width: 1423px) {
  .services-section {
    margin-top: 13%;
  }
}

@media only screen and (max-width: 1392px) {
  .services-section {
    margin-top: 9%;
  }
}

@media only screen and (max-width: 1395px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1383px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1378px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1375px) {
  .services-section {
    margin-top: 14%;
  }
}
@media only screen and (max-width: 1343px) {
  .services-section {
    margin-top: 14%;
  }
}
@media only screen and (max-width: 1340px) {
  .services-section {
    margin-top: 13.5%;
  }
}
@media only screen and (max-width: 1315px) {
  .services-section {
    margin-top: 14%;
  }
}
@media only screen and (max-width: 1303px) {
  .services-section {
    margin-top: 13.8%;
  }
}

@media only screen and (max-width: 1294px) {
  .services-section {
    margin-top: 13.5%;
  }
}
@media only screen and (max-width: 1290px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1265px) {
  .services-section {
    margin-top: 12%;
  }
}
@media only screen and (max-width: 1239px) {
  .services-section {
    margin-top: 11.5%;
  }
}
@media only screen and (max-width: 1227px) {
  .services-section {
    margin-top: 11.5%;
  }
}

@media only screen and (max-width: 1219px) {
  .services-section {
    margin-top: 11%;
  }
}
@media only screen and (max-width: 1194px) {
  .services-section {
    margin-top: 12.5%;
  }
}

@media only screen and (max-width: 1188px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1170px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1160px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1153px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1149px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1146px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1138px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1134px) {
  .services-section {
    margin-top: 13%;
  }
}

@media only screen and (max-width: 1105px) {
  .services-section {
    margin-top: 12.5%;
  }
}

@media only screen and (max-width: 1093px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 1079px) {
  .services-section {
    margin-top: 12.8%;
  }
}
@media only screen and (max-width: 1074px) {
  .services-section {
    margin-top: 12%;
  }
}
@media only screen and (max-width: 1059px) {
  .services-section {
    margin-top: 12%;
  }
}
@media only screen and (max-width: 1037px) {
  .services-section {
    margin-top: 12.5%;
  }
}
@media only screen and (max-width: 1025px) {
  .services-section {
    margin-top: 12%;
  }
}
@media only screen and (max-width: 1009px) {
  .services-section {
    margin-top: 12%;
  }
}
@media only screen and (max-width: 1002px) {
  .services-section {
    margin-top: 11.5%;
  }
}
@media only screen and (max-width: 990px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 985px) {
  .services-section {
    margin-top: 13%;
  }
}

@media only screen and (max-width: 975px) {
  .services-section {
    margin-top: 13%;
  }
}
@media only screen and (max-width: 968px) {
  .services-section {
    margin-top: 12.5%;
  }
}
@media only screen and (max-width: 964px) {
  .services-section {
    margin-top: 12.5%;
  }
}
@media only screen and (max-width: 960px) {
  .services-section {
    margin-top: 12.5%;
  }
}
@media only screen and (max-width: 947px) {
  .services-section {
    margin-top: 12%;
  }
}

@media only screen and (max-width: 940px) {
  .services-section {
    margin-top: 12%;
  }
}
@media only screen and (max-width: 931px) {
  .services-section {
    margin-top: 14%;
  }
}
@media only screen and (max-width: 927px) {
  .services-section {
    margin-top: 13.7%;
  }
}
@media only screen and (max-width: 912px) {
  .services-section {
    margin-top: 13.5%;
  }
}
@media only screen and (max-width: 910px) {
  .services-section {
    margin-top: 9%;
  }
}

@media only screen and (max-width: 892px) {
  .services-section {
    margin-top: 99.5%;
  }
}
@media only screen and (max-width: 888px) {
  .services-section {
    margin-top: 99.5%;
  }
}
@media only screen and (max-width: 882px) {
  .services-section {
    margin-top: 99.5%;
  }
}
@media only screen and (max-width: 864px) {
  .services-section {
    margin-top: 99.5%;
  }
}
@media only screen and (max-width: 540px) {
  .services-section {
    margin-top: 99.5%;
  }
}
@media only screen and (max-width: 413px) {
  .services-section {
    margin-top: 99.5%;
  }
}
@media only screen and (max-width: 390px) {
  .services-section {
    margin-top: 99.5%;
  }
}
@media only screen and (max-width: 393px) {
  .services-section {
    margin-top: 99.5%;
  }
}
@media only screen and (max-width: 378px) {
  .services-section {
    margin-top: 99%;
  }
}
