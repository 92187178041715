.desktop-payment {
  padding: 5%;
  margin-top: 10%;
}
.mobile-payment{
    display: none;
}

@media only screen and (max-width: 700px) {
    .mobile-payment {
      display: block;
    }
    .desktop-payment {
      padding: 5%;
      display: none;
    }
  }