.mobile-view-section3{
  display: none;
}
.desktop-view-section3{
  display: block;
}

@media only screen and (max-width: 750px) {

  .mobile-view-section3{
    display: block;
  }
  .desktop-view-section3{
    display: none;
  }

}