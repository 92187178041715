.video-first-header {
  letter-spacing: 0.00938em;
  color: #1a8fd1;
  font-size: 34px !important;
  line-height: 38px;
  text-align: center;
}
.video-first-header-below {
  letter-spacing: 0.00938em;
  color: #1a8fd1;
  font-weight: 800 !important;

  font-size: 34px !important;
  line-height: 38px;
  text-align: center;
  padding-top: 120px;
}

video:focus {
  outline: none;
}
.down-manu {
  margin-top: -2.5%;
}
.last-down {
  margin-top: -2%;
  margin-left: 3%;
}

.text-1 {
  transform: skew(56deg, -18deg) !important;
  margin-left: 37%;
  margin-top: 20%;
}
.text-1 p {
  transform: perspective(800px) rotateY(24deg) scale(0.9) rotateX(-5deg);
  transition: 0.6s ease all;
  font-size: 1rem;
  font-weight: 600;
  color: #1b8fd2;
  font-family: "Varela Round";
}

.text-2 {
  transform: skew(52deg, -21deg) !important;
  margin-left: 80%;
  margin-top: 24%;
}

.text-2 p {
  transform: perspective(800px) rotateY(24deg) scale(0.9) rotateX(-5deg);
  transition: 0.6s ease all;
  font-size: 1.3rem;
  font-weight: 600;
  color: #1b8fd2;
  font-family: "Varela Round";
}

.text-3 {
  transform: skew(52deg, -18deg) !important;
  color: #1b8fd2;
  margin-left: 26%;
  margin-top: 6%;
}

.text-3 p {
  transform: perspective(800px) rotateY(24deg) scale(0.9) rotateX(-5deg);
  transition: 0.6s ease all;
  font-size: 1rem;
  font-weight: 600;
  color: #1b8fd2;
  font-family: "Varela Round";
}

.text-4 {
  transform: skew(52deg, -21deg) !important;
  color: #1b8fd2;
  margin-left: 51%;
  margin-top: 29%;
}

.text-4 p {
  transform: perspective(800px) rotateY(24deg) scale(0.9) rotateX(-5deg);
  transition: 0.6s ease all;
  font-size: 1rem;
  font-weight: 600;
  color: #1b8fd2;
  font-family: "Varela Round";
}

.text-5 {
  transform: skew(52deg, -21deg) !important;
  color: #1b8fd2;
  margin-left: 71%;
  margin-top: -7.6%;
}

.text-5 p {
  transform: perspective(800px) rotateY(24deg) scale(0.9) rotateX(-5deg);
  transition: 0.6s ease all;
  font-size: 1rem;
  font-weight: 600;
  color: #1b8fd2;
  font-family: "Varela Round";
}
video:focus {
  outline: none;
}

@media only screen and (max-width: 5061px) {
  .text-1 {
    margin-left: 54%;
    margin-top: 22%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 48.5%;
    margin-top: 4.3%;
  }
  .text-3 p {
    font-size: 0.8rem;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 61%;
    margin-top: 33%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 280px;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;

    font-size: 74px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 220px;
  }
}
@media only screen and (max-width: 4900px) {
  .text-1 {
    margin-left: 53%;
    margin-top: 22%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 48%;
    margin-top: 4.3%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 61%;
    margin-top: 33%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 280px;
  }
}
@media only screen and (max-width: 4831px) {
  .text-1 {
    margin-left: 53%;
    margin-top: 22%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 47.5%;
    margin-top: 4.3%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 61%;
    margin-top: 33%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 280px;
  }
}
@media only screen and (max-width: 4685px) {
  .text-1 {
    margin-left: 53%;
    margin-top: 22%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 47%;
    margin-top: 4.3%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 61%;
    margin-top: 32.5%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 280px;
  }
}
@media only screen and (max-width: 4626px) {
  .text-1 {
    margin-left: 52%;
    margin-top: 22%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 46%;
    margin-top: 4.3%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 58%;
    margin-top: 32.5%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 280px;
  }
}
@media only screen and (max-width: 4395px) {
  .text-1 {
    margin-left: 51%;
    margin-top: 22%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 45%;
    margin-top: 5%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 58%;
    margin-top: 32.5%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 280px;
  }
}
@media only screen and (max-width: 4040px) {
  .text-1 {
    margin-left: 49%;
    margin-top: 22%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 43.5%;
    margin-top: 5%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 58%;
    margin-top: 32%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 280px;
  }
}
@media only screen and (max-width: 3885px) {
  .text-1 {
    margin-left: 49%;
    margin-top: 22%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 43%;
    margin-top: 5%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 58%;
    margin-top: 32%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.8%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 280px;
  }
}
@media only screen and (max-width: 3818px) {
  .text-1 {
    margin-left: 48%;
    margin-top: 22%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 42%;
    margin-top: 5%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 58%;
    margin-top: 31.5%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 280px;
  }
}
@media only screen and (max-width: 3795px) {
  .text-1 {
    margin-left: 48%;
    margin-top: 22%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 42%;
    margin-top: 5%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 58%;
    margin-top: 31.5%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 240px;
  }
}
@media only screen and (max-width: 3751px) {
  .text-1 {
    margin-left: 48%;
    margin-top: 22%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 42%;
    margin-top: 5%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 58%;
    margin-top: 31.5%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 240px;
  }
}
@media only screen and (max-width: 3693px) {
  .text-1 {
    margin-left: 48%;
    margin-top: 22%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 42%;
    margin-top: 5%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 58%;
    margin-top: 31.5%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 280px;
  }
}
@media only screen and (max-width: 3591px) {
  .text-1 {
    margin-left: 48%;
    margin-top: 22%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 40%;
    margin-top: 5%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 58%;
    margin-top: 31%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 280px;
  }
}
@media only screen and (max-width: 3573px) {
  .text-1 {
    margin-left: 47%;
    margin-top: 22.5%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 40%;
    margin-top: 5%;
  }

  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 58%;
    margin-top: 31%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 280px;
  }
}
@media only screen and (max-width: 3315px) {
  .text-1 {
    margin-left: 46%;
    margin-top: 22.5%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 39%;
    margin-top: 5%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 58%;
    margin-top: 31%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 280px;
  }
}
@media only screen and (max-width: 3094px) {
  .text-1 {
    margin-left: 46%;
    margin-top: 22.5%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 38%;
    margin-top: 5%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 58%;
    margin-top: 31%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 280px;
  }
}
@media only screen and (max-width: 3055px) {
  .text-1 {
    margin-left: 45%;
    margin-top: 22.5%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 28%;
  }
  .text-3 {
    margin-left: 37%;
    margin-top: 5%;
  }
  .last-down {
    margin-top: 1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 58%;
    margin-top: 31%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 20px;
  }
}
@media only screen and (max-width: 2791px) {
  .text-1 {
    margin-left: 44%;
    margin-top: 23%;
  }
  .down-manu {
    margin-top: -0.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 26%;
  }
  .text-3 {
    margin-left: 36%;
    margin-top: 6%;
  }
  .last-down {
    margin-top: -0.5%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 56%;
    margin-top: 31%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;

    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 180px;
  }
}
@media only screen and (max-width: 2723px) {
  .text-1 {
    margin-left: 43.5%;
    margin-top: 23%;
  }

  .text-2 {
    margin-left: 80%;
    margin-top: 26%;
  }
  .text-3 {
    margin-left: 36%;
    margin-top: 6%;
  }

  .text-4 {
    margin-left: 56%;
    margin-top: 31%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.8%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}
@media only screen and (max-width: 2626px) {
  .text-1 {
    margin-left: 43%;
    margin-top: 23%;
  }

  .text-2 {
    margin-left: 80%;
    margin-top: 26%;
  }
  .text-3 {
    margin-left: 36%;
    margin-top: 6%;
  }

  .text-4 {
    margin-left: 56%;
    margin-top: 30%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}
@media only screen and (max-width: 2582px) {
  .text-1 {
    margin-left: 42.5%;
    margin-top: 23%;
  }

  .text-2 {
    margin-left: 80%;
    margin-top: 26%;
  }
  .text-3 {
    margin-left: 34%;
    margin-top: 6%;
  }

  .text-4 {
    margin-left: 56%;
    margin-top: 30%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}

@media only screen and (max-width: 2574px) {
  .text-1 {
    margin-left: 42.5%;
    margin-top: 23%;
  }

  .text-2 {
    margin-left: 80%;
    margin-top: 26%;
  }
  .text-3 {
    margin-left: 34%;
    margin-top: 6%;
  }

  .text-4 {
    margin-left: 56%;
    margin-top: 30%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}
@media only screen and (max-width: 2471px) {
  .text-1 {
    margin-left: 42%;
    margin-top: 23%;
  }

  .text-2 {
    margin-left: 80%;
    margin-top: 26%;
  }
  .text-3 {
    margin-left: 34%;
    margin-top: 6%;
  }

  .text-4 {
    margin-left: 56%;
    margin-top: 30%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}
@media only screen and (max-width: 2386px) {
  .text-1 {
    margin-left: 41.5%;
    margin-top: 23%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 26%;
  }
  .text-3 {
    margin-left: 32.5%;
    margin-top: 6.5%;
  }
  .last-down {
    margin-top: -0.5%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 56%;
    margin-top: 30.5%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}
@media only screen and (max-width: 2268px) {
  .text-1 {
    margin-left: 41%;
    margin-top: 23%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 26%;
  }
  .text-3 {
    margin-left: 31%;
    margin-top: 6%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 31.5%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -5.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}
@media only screen and (max-width: 2209px) {
  .text-1 {
    margin-left: 41%;
    margin-top: 23.5%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 26%;
  }
  .text-3 {
    margin-left: 31%;
    margin-top: 5.5%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 31%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -6.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}
@media only screen and (max-width: 2048px) {
  .text-1 {
    margin-left: 40%;
    margin-top: 23.5%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 26%;
  }
  .text-3 {
    margin-left: 31%;
    margin-top: 5.5%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 31%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -6.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}
@media only screen and (max-width: 1991px) {
  .text-1 {
    margin-left: 40%;
    margin-top: 23.6%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 26%;
  }
  .text-3 {
    margin-left: 31%;
    margin-top: 5.5%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 30%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -6.6%;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;

    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 120px;
  }
}
@media only screen and (max-width: 1965px) {
  .text-1 {
    margin-left: 39.3%;
    margin-top: 23.6%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 26%;
  }
  .text-3 {
    margin-left: 31%;
    margin-top: 5.5%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 30%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -7%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}

@media only screen and (max-width: 1958px) {
  .text-1 {
    margin-left: 39.3%;
    margin-top: 23.6%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 26%;
  }
  .text-3 {
    margin-left: 30%;
    margin-top: 5.5%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 30%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -7%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}

@media only screen and (max-width: 1921px) {
  .text-1 {
    margin-left: 39%;
    margin-top: 23.6%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 25%;
  }
  .text-3 {
    margin-left: 30%;
    margin-top: 6%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 30%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -6.6%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}

@media only screen and (max-width: 1802px) {
  .text-1 {
    margin-left: 39%;
    margin-top: 23.6%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 25%;
  }
  .text-3 {
    margin-left: 28%;
    margin-top: 6%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 30%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -6.6%;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;

    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 120px;
  }
}
@media only screen and (max-width: 1661px) {
  .text-1 {
    margin-left: 38%;
    margin-top: 24%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 25%;
  }
  .text-3 {
    margin-left: 28%;
    margin-top: 6%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 30%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -7%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}
@media only screen and (max-width: 1639px) {
  .text-1 {
    margin-left: 38%;
    margin-top: 24%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 25%;
  }
  .text-3 {
    margin-left: 28%;
    margin-top: 5.5%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 29%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -6.7%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}

@media only screen and (max-width: 1632px) {
  .text-1 {
    margin-left: 38%;
    margin-top: 24%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 25%;
  }
  .text-3 {
    margin-left: 28%;
    margin-top: 5.5%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 29%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -6.7%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}

@media only screen and (max-width: 1570px) {
  .text-1 {
    margin-left: 38%;
    margin-top: 24%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 25%;
  }
  .text-3 {
    margin-left: 28%;
    margin-top: 5.5%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 29%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -6.7%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}
@media only screen and (max-width: 1552px) {
  .text-1 {
    margin-left: 38%;
    margin-top: 24%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 25%;
  }
  .text-3 {
    margin-left: 28%;
    margin-top: 5.5%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 29%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -7.5%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}
@media only screen and (max-width: 1524px) {
  .text-1 {
    margin-left: 39%;
    margin-top: 23.6%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 25%;
  }
  .text-3 {
    margin-left: 28%;
    margin-top: 6%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 29%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -8%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}

@media only screen and (max-width: 1517px) {
  .text-1 {
    margin-left: 39%;
    margin-top: 23.6%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 25%;
  }
  .text-3 {
    margin-left: 28%;
    margin-top: 6%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 28%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -7%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}

@media only screen and (max-width: 1484px) {
  .text-1 {
    margin-left: 37.5%;
    margin-top: 24%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 24%;
  }
  .text-3 {
    margin-left: 27%;
    margin-top: 6%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 28.5%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -7%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}
@media only screen and (max-width: 1474px) {
  .text-1 {
    margin-left: 37.5%;
    margin-top: 24%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 24%;
  }
  .text-3 {
    margin-left: 27%;
    margin-top: 6%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 28.5%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -6.5%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}
@media only screen and (max-width: 1424px) {
  .text-1 {
    margin-left: 37.5%;
    margin-top: 24%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 24%;
  }
  .text-3 {
    margin-left: 27%;
    margin-top: 6%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 2.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 29%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -7.5%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}

@media only screen and (max-width: 1395px) {
  .text-1 {
    margin-left: 37.5%;
    margin-top: 24%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 24%;
  }
  .text-3 {
    margin-left: 27%;
    margin-top: 6%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 28%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -8%;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;

    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 120px;
  }
}
@media only screen and (max-width: 1393px) {
  .text-1 {
    margin-left: 37.5%;
    margin-top: 24%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 24%;
  }
  .text-3 {
    margin-left: 27%;
    margin-top: 6%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 28.5%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -7%;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 200px;
  }
}

@media only screen and (max-width: 1377px) {
  .text-1 {
    margin-left: 37.5%;
    margin-top: 24%;
  }
  .down-manu {
    margin-top: -1.5%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 24%;
  }
  .text-3 {
    margin-left: 27%;
    margin-top: 6%;
  }
  .last-down {
    margin-top: -1%;
    margin-left: 1.5%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 28%;
  }
  .text-5 {
    margin-left: 71%;
    margin-top: -8%;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;

    font-size: 34px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 100px;
  }
}

@media only screen and (max-width: 1343px) {
  .text-5 {
    margin-left: 69%;
    margin-top: -8%;
  }
  .text-4 {
    margin-left: 51%;
    margin-top: 28%;
  }
}
@media only screen and (max-width: 1315px) {
  .text-2 {
    margin-left: 80%;
    margin-top: 23%;
  }
  .text-3 {
    margin-left: 25%;
    margin-top: 6.5%;
  }
  .text-5 {
    margin-left: 69%;
    margin-top: -8%;
  }
  .text-4 {
    margin-left: 51%;
    margin-top: 28%;
  }
}

@media only screen and (max-width: 1295px) {
  .text-1 {
    margin-left: 36.6%;
    margin-top: 24%;
  }
  .text-4 {
    margin-left: 53%;
    margin-top: 28%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -7.5%;
  }
  .text-3 {
    margin-left: 25%;
    margin-top: 6%;
  }
}
@media only screen and (max-width: 1285px) {
  .text-1 {
    margin-left: 36.6%;
    margin-top: 24%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 29%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8%;
  }
  .text-3 {
    margin-left: 25%;
    margin-top: 6%;
  }
}
@media only screen and (max-width: 1264px) {
  .text-3 {
    margin-left: 25%;
    margin-top: 6%;
  }
  .text-4 {
    margin-left: 48%;
    margin-top: 30.5%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8%;
  }
}
@media only screen and (max-width: 1259px) {
  .text-3 {
    margin-left: 25%;
    margin-top: 6%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 30.5%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8%;
  }
}
@media only screen and (max-width: 1193px) {
  .text-2 {
    margin-left: 80%;
    margin-top: 23%;
  }
  .text-3 {
    margin-left: 25%;
    margin-top: 6%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 29%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8%;
  }
}
@media only screen and (max-width: 1191px) {
  .text-2 {
    margin-left: 80%;
    margin-top: 23%;
  }
  .text-3 {
    margin-left: 25%;
    margin-top: 6%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 29%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8%;
  }
}
@media only screen and (max-width: 1189px) {
  .text-3 {
    margin-left: 24%;
    margin-top: 6%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 28%;
  }
  .text-5 {
    margin-left: 69%;
    margin-top: -9%;
  }
}
@media only screen and (max-width: 1170px) {
  .text-2 {
    margin-left: 80%;
    margin-top: 23%;
  }
  .text-3 {
    margin-left: 25%;
    margin-top: 6%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 27%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8%;
  }
}
@media only screen and (max-width: 1151px) {
  .down-manu {
    margin-top: -3%;
  }
  .last-down {
    margin-top: -2.5%;
    margin-left: 3%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 23.5%;
  }
  .text-3 {
    margin-left: 25%;
    margin-top: 6%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 29%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8.5%;
  }
}
@media only screen and (max-width: 1130px) {
  .text-2 {
    margin-left: 80%;
    margin-top: 24%;
  }
  .text-3 {
    margin-left: 25%;
    margin-top: 6%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 27.5%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8%;
  }
}

@media only screen and (max-width: 1093px) {
  .text-1 {
    
    margin-left: 35.3%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 24%;
  }
  .text-3 {
    margin-left: 25%;
    margin-top: 6%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 27.5%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8.5%;
  }
}
@media only screen and (max-width: 1079px) {
  .text-2 {
    margin-left: 80%;
    margin-top: 24%;
  }
  .text-3 {
    margin-left: 24%;
    margin-top: 6%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 28%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8%;
  }
  .down-manu {
    margin-top: -3%;
  }
  .last-down {
    margin-top: -3%;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 1077px) {
  .text-1 {
    margin-left: 36%;
    margin-top: 24.6%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 23%;
  }
  .text-3 {
    margin-left: 24%;
    margin-top: 6%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 28%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8%;
  }
  .down-manu {
    margin-top: -3%;
  }
  .last-down {
    margin-top: -3%;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 1067px) {
  .text-1 {
    margin-left: 36%;
    margin-top: 24.6%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 23%;
  }
  .text-3 {
    margin-left: 24%;
    margin-top: 6%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 28%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8%;
  }
  .down-manu {
    margin-top: -3%;
  }
  .last-down {
    margin-top: -3%;
    margin-left: 3%;
  }
}
@media only screen and (max-width: 1049px) {
  .text-1 {
    margin-left: 36%;
    margin-top: 24.6%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 23%;
  }
  .text-3 {
    margin-left: 24%;
    margin-top: 6%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 28%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -9%;
  }
  .down-manu {
    margin-top: -3%;
  }
  .last-down {
    margin-top: -3%;
    margin-left: 3%;
  }
}
@media only screen and (max-width: 1033px) {
  .text-1 {
    margin-left: 36%;
    margin-top: 24.6%;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 23%;
  }
  .text-3 {
    margin-left: 24%;
    margin-top: 6%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 27%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8%;
  }
  .down-manu {
    margin-top: -3%;
  }
  .last-down {
    margin-top: -3%;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 990px) {
  .text-2 {
    margin-left: 80%;
    margin-top: 23%;
  }
  .text-3 {
    margin-left: 24%;
    margin-top: 5%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 28%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -9%;
  }
  .down-manu {
    margin-top: -3%;
  }
  .last-down {
    margin-top: -3%;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 988px) {
  .text-2 {
    margin-left: 80%;
    margin-top: 23%;
  }
  .text-3 {
    margin-left: 24%;
    margin-top: 5%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 28%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -9.6%;
  }
  .down-manu {
    margin-top: -3%;
  }
  .last-down {
    margin-top: -3%;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 980px) {
  .text-1 {
    margin-left: 35%;
    margin-top: 24.6%;
  }

  .text-2 {
    margin-left: 80%;
    margin-top: 23%;
  }
  .text-3 {
    margin-left: 24%;
    margin-top: 5%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 27%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8.5%;
  }
  .down-manu {
    margin-top: -3%;
  }
  .last-down {
    margin-top: -3%;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 972px) {
  .text-1 {
    margin-left: 35%;
    margin-top: 24.6%;
  }

  .text-2 {
    margin-left: 80%;
    margin-top: 23%;
  }
  .text-3 {
    margin-left: 24%;
    margin-top: 5%;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 27%;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8.5%;
  }
  .down-manu {
    margin-top: -3%;
  }
  .last-down {
    margin-top: -3%;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 931px) {
  .text-1 {
    margin-left: 35%;
    margin-top: 24.5%;
  }
  .text-1 p {
    font-size: 0.7rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 24%;
  }
  .text-2 p {
    font-size: 0.9rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 7%;
  }
  .text-3 p {
    font-size: 0.7rem;
  }
  .text-4 {
    margin-left: 52%;
    margin-top: 28%;
  }
  .text-4 p {
    font-size: 0.7rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8%;
  }
  .text-5 p {
    font-size: 0.7rem;
  }
  .last-down {
    margin-top: -2%;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 912px) {
  .text-1 {
    margin-left: 35%;
    margin-top: 24.5%;
  }
  .text-1 p {
    font-size: 0.7rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 23%;
  }
  .text-2 p {
    font-size: 0.9rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 7%;
  }
  .text-3 p {
    font-size: 0.7rem;
  }
  .text-4 {
    margin-left: 48%;
    margin-top: 29%;
    position: relative;
  }
  .text-4 p {
    font-size: 0.7rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8%;
    position: relative;
  }
  .text-5 p {
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 909px) {
  .text-1 {
    margin-left: 35%;
    margin-top: 24.5%;
  }
  .text-1 p {
    font-size: 0.7rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 23%;
  }
  .text-2 p {
    font-size: 0.9rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 7%;
  }
  .text-3 p {
    font-size: 0.7rem;
  }
  .text-4 {
    margin-left: 48%;
    margin-top: 29%;
    position: relative;
  }
  .text-4 p {
    font-size: 0.7rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: -8%;
    position: relative;
  }
  .text-5 p {
    font-size: 0.7rem;
  }
  video {
    padding: 0px !important;
  }
}

@media only screen and (max-width: 892px) {
  .text-1 {
    margin-left: 36%;
    margin-top: 28.5%;
    position: absolute;
  }
  .down-manu {
    margin-top: -15%;
  }
  .text-1 p {
    font-size: 0.7rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 50%;
    position: absolute;
  }
  .text-2 p {
    font-size: 0.7rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 67%;
    position: absolute;
  }
  .last-down {
    margin-top: -23%;
    margin-left: 19%;
  }
  .text-3 p {
    font-size: 0.7rem;
  }
  .text-4 {
    margin-left: 50%;
    margin-top: 97%;
    position: absolute;
  }
  .text-4 p {
    font-size: 0.7rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: 87.6%;
    position: absolute;
  }
  .text-5 p {
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 705px) {
  .text-1 {
    margin-left: 36%;
    margin-top: 28.5%;
    position: absolute;
  }
  .down-manu {
    margin-top: -15%;
  }
  .text-1 p {
    font-size: 0.7rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 50%;
    position: absolute;
  }
  .text-2 p {
    font-size: 0.7rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 67%;
    position: absolute;
  }
  .last-down {
    margin-top: -23%;
    margin-left: 19%;
  }
  .text-3 p {
    font-size: 0.7rem;
  }
  .text-4 {
    margin-left: 50%;
    margin-top: 97%;
    position: absolute;
  }
  .text-4 p {
    font-size: 0.7rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: 87.6%;
    position: absolute;
  }
  .text-5 p {
    font-size: 0.7rem;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 24px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 100px;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;
    font-size: 24px !important;
    line-height: 38px;
    text-align: center;
  }
}

@media only screen and (max-width: 656px) {
  .text-1 {
    margin-left: 36%;
    margin-top: 28.5%;
    position: absolute;
  }
  .down-manu {
    margin-top: -15%;
  }
  .text-1 p {
    font-size: 0.5rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 50%;
    position: absolute;
  }
  .text-2 p {
    font-size: 0.5rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 67%;
    position: absolute;
  }
  .last-down {
    margin-top: -23%;
    margin-left: 19%;
  }
  .text-3 p {
    font-size: 0.5rem;
  }
  .text-4 {
    margin-left: 50%;
    margin-top: 97%;
    position: absolute;
  }
  .text-4 p {
    font-size: 0.5rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: 87.6%;
    position: absolute;
  }
  .text-5 p {
    font-size: 0.5rem;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 24px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 100px;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;
    font-size: 24px !important;
    line-height: 38px;
    text-align: center;
  }
}

@media only screen and (max-width: 473px) {
  .text-1 {
    margin-left: 36%;
    margin-top: 28%;
    position: absolute;
  }
  .down-manu {
    margin-top: -15%;
  }
  .text-1 p {
    font-size: 0.5rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 49%;
    position: absolute;
  }
  .text-2 p {
    font-size: 0.5rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 66.5%;
    position: absolute;
  }
  .last-down {
    margin-top: -23%;
    margin-left: 19%;
  }
  .text-3 p {
    font-size: 0.5rem;
  }
  .text-4 {
    margin-left: 50%;
    margin-top: 96%;
    position: absolute;
  }
  .text-4 p {
    font-size: 0.5rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: 87%;
    position: absolute;
  }
  .text-5 p {
    font-size: 0.5rem;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 24px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 100px;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;
    font-size: 24px !important;
    line-height: 38px;
    text-align: center;
  }
}
@media only screen and (max-width: 390px) {
  .text-1 {
    margin-left: 34%;
    margin-top: 28%;
    position: absolute;
  }
  .down-manu {
    margin-top: -15%;
  }
  .text-1 p {
    font-size: 0.4rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 49%;
    position: absolute;
  }
  .text-2 p {
    font-size: 0.4rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 66.5%;
    position: absolute;
  }
  .last-down {
    margin-top: -23%;
    margin-left: 19%;
  }
  .text-3 p {
    font-size: 0.4rem;
  }
  .text-4 {
    margin-left: 50%;
    margin-top: 96%;
    position: absolute;
  }
  .text-4 p {
    font-size: 0.4rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: 87%;
    position: absolute;
  }
  .text-5 p {
    font-size: 0.4rem;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 15px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 100px;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 38px;
    text-align: center;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .text-1 {
    margin-left: 34%;
    margin-top: 28%;
    position: absolute;
  }
  .down-manu {
    margin-top: -15%;
  }
  .text-1 p {
    font-size: 0.4rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 49%;
    position: absolute;
  }
  .text-2 p {
    font-size: 0.4rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 66.5%;
    position: absolute;
  }
  .last-down {
    margin-top: -23%;
    margin-left: 19%;
  }
  .text-3 p {
    font-size: 0.4rem;
  }
  .text-4 {
    margin-left: 50%;
    margin-top: 96%;
    position: absolute;
  }
  .text-4 p {
    font-size: 0.4rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: 87%;
    position: absolute;
  }
  .text-5 p {
    font-size: 0.4rem;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 15px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 100px;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 38px;
    text-align: center;
  }
}
@media only screen and (max-width: 380px) {
  .text-1 {
    margin-left: 36%;
    margin-top: 28%;
    position: absolute;
  }
  .down-manu {
    margin-top: -15%;
  }
  .text-1 p {
    font-size: 0.4rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 49%;
    position: absolute;
  }
  .text-2 p {
    font-size: 0.4rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 66.5%;
    position: absolute;
  }
  .last-down {
    margin-top: -23%;
    margin-left: 19%;
  }
  .text-3 p {
    font-size: 0.4rem;
  }
  .text-4 {
    margin-left: 50%;
    margin-top: 96%;
    position: absolute;
  }
  .text-4 p {
    font-size: 0.4rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: 87%;
    position: absolute;
  }
  .text-5 p {
    font-size: 0.4rem;
  }

  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 80px;
  }
}

@media only screen and (max-width: 358px) {
  .text-1 {
    margin-left: 35%;
    margin-top: 28%;
    position: absolute;
  }
  .down-manu {
    margin-top: -15%;
  }
  .text-1 p {
    font-size: 0.4rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 49%;
    position: absolute;
  }
  .text-2 p {
    font-size: 0.4rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 66.5%;
    position: absolute;
  }
  .last-down {
    margin-top: -23%;
    margin-left: 19%;
  }
  .text-3 p {
    font-size: 0.4rem;
  }
  .text-4 {
    margin-left: 50%;
    margin-top: 96%;
    position: absolute;
  }
  .text-4 p {
    font-size: 0.4rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: 87%;
    position: absolute;
  }
  .text-5 p {
    font-size: 0.4rem;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 15px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 100px;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 38px;
    text-align: center;
  }
}

@media only screen and (max-width: 336px) {
  .text-1 {
    margin-left: 36%;
    margin-top: 28%;
    position: absolute;
  }
  .down-manu {
    margin-top: -15%;
  }
  .text-1 p {
    font-size: 0.3rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 49%;
    position: absolute;
  }
  .text-2 p {
    font-size: 0.3rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 66.5%;
    position: absolute;
  }
  .last-down {
    margin-top: -23%;
    margin-left: 19%;
  }
  .text-3 p {
    font-size: 0.3rem;
  }
  .text-4 {
    margin-left: 50%;
    margin-top: 96%;
    position: absolute;
  }
  .text-4 p {
    font-size: 0.3rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: 87%;
    position: absolute;
  }
  .text-5 p {
    font-size: 0.3rem;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 15px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 100px;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 38px;
    text-align: center;
  }
}

@media only screen and (max-width: 298px) {
  .text-1 {
    margin-left: 35%;
    margin-top: 27%;
    position: absolute;
  }
  .down-manu {
    margin-top: -15%;
  }
  .text-1 p {
    font-size: 0.3rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 49%;
    position: absolute;
  }
  .text-2 p {
    font-size: 0.3rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 66.5%;
    position: absolute;
  }
  .last-down {
    margin-top: -23%;
    margin-left: 19%;
  }
  .text-3 p {
    font-size: 0.3rem;
  }
  .text-4 {
    margin-left: 50%;
    margin-top: 96%;
    position: absolute;
  }
  .text-4 p {
    font-size: 0.3rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: 87%;
    position: absolute;
  }
  .text-5 p {
    font-size: 0.3rem;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 15px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 100px;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 38px;
    text-align: center;
  }
}

@media only screen and (max-width: 275px) {
  .text-1 {
    margin-left: 36%;
    margin-top: 28%;
    position: absolute;
  }
  .down-manu {
    margin-top: -15%;
  }
  .text-1 p {
    font-size: 0.3rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 49%;
    position: absolute;
  }
  .text-2 p {
    font-size: 0.3rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 66.5%;
    position: absolute;
  }
  .last-down {
    margin-top: -23%;
    margin-left: 19%;
  }
  .text-3 p {
    font-size: 0.3rem;
  }
  .text-4 {
    margin-left: 50%;
    margin-top: 96%;
    position: absolute;
  }
  .text-4 p {
    font-size: 0.3rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: 87%;
    position: absolute;
  }
  .text-5 p {
    font-size: 0.3rem;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 15px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 100px;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 38px;
    text-align: center;
  }
}

@media only screen and (max-width: 230px) {
  .text-1 {
    margin-left: 36%;
    margin-top: 28%;
    position: absolute;
  }
  .down-manu {
    margin-top: -15%;
  }
  .text-1 p {
    font-size: 0.2rem;
  }
  .text-2 {
    margin-left: 80%;
    margin-top: 49%;
    position: absolute;
  }
  .text-2 p {
    font-size: 0.2rem;
  }
  .text-3 {
    margin-left: 23%;
    margin-top: 66.5%;
    position: absolute;
  }
  .last-down {
    margin-top: -23%;
    margin-left: 19%;
  }
  .text-3 p {
    font-size: 0.2rem;
  }
  .text-4 {
    margin-left: 50%;
    margin-top: 96%;
    position: absolute;
  }
  .text-4 p {
    font-size: 0.2rem;
  }
  .text-5 {
    margin-left: 68%;
    margin-top: 87%;
    position: absolute;
  }
  .text-5 p {
    font-size: 0.2rem;
  }
  .video-first-header {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-size: 15px !important;
    line-height: 38px;
    text-align: center;
    padding-top: 100px;
  }
  .video-first-header-below {
    letter-spacing: 0.00938em;
    color: #1a8fd1;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 38px;
    text-align: center;
  }
}
