.mobile-header {
  display: none;
}
.desktop-header {
  flex-grow: 1;
  display: block;
}

.desktop-menu {
  margin-left: 2% !important;
}

.desktop-logo-image {
  width: 40%;
  padding: 10px;
}

.mobile-logo-image {
  width: 40%;
  padding: 10px;
  margin: auto;
  display: block;
}

.track-btn-grid{
  margin-top: 1.7% !important
}

.mobile-track{
  display:  none !important;
}
.desktop-track{
  display : "block" !important
}

.mobile-track-order-details{
  display:  none;
}
.desktop-track-order-details{
  display: block;
}

.rotate {
  animation: rotate 1.5s linear infinite;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 5061px) {
  .desktop-logo-image {
    width: 20%;
    padding: 10px;
  }
}
@media only screen and (max-width: 3795px) {
  .desktop-logo-image {
    width: 30%;
    padding: 10px;
  }
}

@media only screen and (max-width: 1265px) {
  .desktop-menu {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 1253px) {
  .desktop-menu {
    font-size: 0.8rem !important;
  }
}
@media only screen and (max-width: 1210px) {
  .desktop-menu {
    font-size: 0.7rem !important;
  }
  .desktop-logo-image {
    width: 30%;
  }
}
@media only screen and (max-width: 1137px) {
  .desktop-menu {
    font-size: 0.6rem !important;
  }
  .desktop-logo-image {
    width: 30%;
  }
  .track-btn-grid{
    margin-top: 2% !important
  }
}
@media only screen and (max-width: 1072px) {
  .desktop-menu {
    font-size: 0.4rem !important;
  }
  .desktop-logo-image {
    width: 28%;
  }
  .track-btn-grid{
    margin-top: 2% !important
  }
}
@media only screen and (max-width: 1017px) {
  .desktop-menu {
    font-size: 0.4rem !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
}

@media only screen and (max-width: 974px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 1% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .track-btn-grid{
    margin-top: 2.5% !important
  }
}

@media only screen and (max-width: 910px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
}

@media only screen and (max-width: 900px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 87%;
  }
  .track-btn-grid{
    margin-top: 2.5% !important
  }
}
@media only screen and (max-width: 855px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 75%;
  }
}
@media only screen and (max-width: 793px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 73%;
  }
}
@media only screen and (max-width: 778px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 65%;
  }
  .track-btn-grid{
    margin-top: 3% !important
  }
}
@media only screen and (max-width: 778px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 62%;
  }
  .track-btn-grid{
    margin-top: 3% !important
  }
}
@media only screen and (max-width: 700px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 55%;
  }
  .track-btn-grid{
    margin-top: 4% !important
  }
  
  
}
@media only screen and (max-width: 656px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 50%;
  }
  .track-btn-grid{
    margin-top: 4% !important
  }
}
@media only screen and (max-width: 613px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 45%;
  }
}
@media only screen and (max-width: 565px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 40%;
  }
}
@media only screen and (max-width: 544px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 35%;
  }
}
@media only screen and (max-width: 496px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 29%;
  }
  .track-btn-grid{
    margin-top: 5% !important
  }
}
@media only screen and (max-width: 487px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 29%;
  }
  .track-btn-grid{
    margin-top: 5% !important
  }
  /* .mobile-track-order-details{
    display: block;
  }
  .desktop-track-order-details{
    display: none;
  } */
}
@media only screen and (max-width: 451px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 27%;
  }
  .track-btn-grid{
    margin-top: 5% !important
  }
  .mobile-track{
    display: block !important;
  }
  .desktop-track{
    display : none !important
  }
 

}
@media only screen and (max-width: 429px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 27%;
  }
  .track-btn-grid{
    margin-top: 6% !important
  }
}

@media only screen and (max-width: 400px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 27%;
  }
  .track-btn-grid{
    margin-top: 6% !important
  }
  .mobile-track{
    display: block !important;
  }
  .desktop-track{
    display : none !important
  }
  
}
@media only screen and (max-width: 374px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 25%;
  }
  .track-btn-grid{
    margin-top: 6% !important
  }
  .mobile-track{
    display: block !important;
  }
  .desktop-track{
    display : none !important
  }
  
}
@media only screen and (max-width: 368px) {
  .desktop-menu {
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image {
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
  .mobile-logo-image{
    margin-left: 25%;
  }
  .track-btn-grid{
    margin-top: 6% !important
  }
  .mobile-track{
    display: block !important;
  }
  .desktop-track{
    display : none !important
  }
  
}

/* @media only screen and (max-width: 1190px) {
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
} */
