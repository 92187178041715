.desktop-map {
  display: block;
}
.mobile-map {
  display: none;
}
@media only screen and (max-width: 900px) {
  .mobile-map {
    display: block;
  }
  .desktop-map {
    display: none;
  }
}
