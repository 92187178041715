.track-pickup-mobile {
  display: none;
}
.track-pickup-desktop {
  display: block;
}

@media only screen and (max-width: 900px) {
  .track-pickup-mobile {
    display: block;
  }
  .track-pickup-desktop {
    display: none;
  }
}
