.mainHeader {
    margin-top: 13vh !important;
}

.mainContent{
    padding-left: 0px !important;
}

.textField {
    width: 27ch;
}

.imgVehicle {
    display: flex;
    justify-content: center;
}

.formContainer {
    margin-left: 2vh;
}

.previewImage {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
    border: 2px solid transparent; /* Initial border style */
    object-fit: fill;
  }
  
  .previewImage:hover {
    border: 2px solid #1b8fd2; /* Border color on hover */
    cursor: pointer;
    
  }

  .previewPdf {
    min-width: 10vh;
    max-width: 10vh;
    height: 10vh;
    min-height: 10vh;
    max-height: 10vh;
    margin: 1vh;
    border: 2px solid transparent; /* Initial border style */
    object-fit: fill;
  }
  
  .previewPdf:hover {
    border: 2px solid #1b8fd2; /* Border color on hover */
    cursor: pointer;
    
  }
  


@media only screen and (max-width: 767px) {
    .formContainer {
        padding-left: 2vh !important;
    }

    .docForm{
        padding-right: 21px;
    }

    .searchButton{

    }

    .hide {
        display: none;
    }

    .textField {
        width: 100%;
        margin-top: 10px !important;
    }

    .mobileHeading {
        padding-left: 15px;
        padding-top: 0px;
        width: '';
    }

    .mainHeader {
        margin-top: 12vh !important;
    }
}