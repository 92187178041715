.mobile-orders-details {
    display: none;
  }
  .desktop-order-details {
    display: block;
  }
  
  @media only screen and (max-width: 900px) {
    .mobile-orders-details {
      display: block;
    }
    .desktop-order-details {
      display: none;
    }
  }
  