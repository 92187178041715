.track-desktop {
  display: block;
}

.track-mobile-view {
  display: none;
}

.text-name {
  font-size: 15px;
  font-weight: 600;
}
.text-address {
  font-size: 12px;
  color: grey;
}
.text-mobile {
  font-size: 12px;
  color: grey;
}

@media only screen and (max-width: 866px) {
  .text-name {
    font-size: 12px;
    font-weight: 600;
  }
  .text-address {
    font-size: 10px;
    color: grey;
  }
  .text-mobile {
    font-size: 8px;
    color: grey;
  }
}
@media only screen and (max-width: 777px) {
  .text-name {
    font-size: 10px;
    font-weight: 600;
  }
  .text-address {
    font-size: 8px;
    color: grey;
  }
  .text-mobile {
    font-size: 6px;
    color: grey;
  }
}
@media only screen and (max-width: 640px) {
  .track-desktop {
    display: none;
  }

  .track-mobile-view {
    display: block;
  }
}
@media only screen and (max-width: 450px) {
  .track-desktop {
    display: none;
  }

  .track-mobile-view {
    display: block;
  }
}
