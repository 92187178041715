.mobile-footer{
    display: none;
}
.desktop-footer{
    display: block;
}

.quick-links:hover{
    color: #1a8fd1;
}

@media only screen and (max-width: 800px){

    .mobile-footer{
        display: block;
    }
    .desktop-footer{
        display: none;
    }

}