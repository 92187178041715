.mobile-header {
  display: none;
}
.desktop-header {
  flex-grow: 1;
  display: block;
}

.rotate {
  animation: rotate 1.5s linear infinite;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 900px) {
  .desktop-menu{
    font-size: 0.4rem !important;
    margin-left: 0.5% !important;
  }
  .desktop-logo-image{
    width: 25%;
  }
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }

}


/* @media only screen and (max-width: 1190px) {
  .mobile-header {
    display: block;
  }
  .desktop-header {
    flex-grow: 1;
    display: none;
  }
} */
