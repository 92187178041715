.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (min-width: 1024px) {
  ._1A98vVyC:after {
    border-left-width: calc(50vw - 0.5 * var(--sbw));
    border-bottom-width: 0;
    border-right-width: calc(50vw - 0.5 * var(--sbw));
    border-top-width: calc(25.69444vw - 0.25694 * var(--sbw));
  }
}
._1A98vVyC:after {
  content: "";
  position: absolute;
  z-index: 0;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50vw solid rgba(0, 0, 0, 0);
  border-bottom: 0 solid rgba(0, 0, 0, 0);
  border-right: 50vw solid rgba(0, 0, 0, 0);
  border-top: calc(26.66667vw - 0.26667 * var(--sbw)) solid #fbf9fc;
  margin-top: -1px;
}
