.join-head {
  font-size: 10rem !important;
  font-weight: 600 !important;
  text-align: center;
  position: absolute;
  text-align: center;
  color: #fff;
  padding: 10%;
  z-index: 1000;
  margin-top: 10%;
  text-decoration: none;
}
.join-text {
  font-size: 1.5rem;
  text-align: center;
}
.join-text-box {
  margin-top: 20%;
  position: absolute;
  padding: 5%;
}
.mobile-video-join {
  display: none;
}
.join-btn {
  position: absolute;
  cursor: pointer;
  z-index: 1000;
  font-size: 2rem;
  border: 2px solid #fff;
  padding: 1%;
  color: #fff;
  border-radius: 7px;
  margin-top: 35%;
  margin-left: 47%;
  text-decoration: none;
}
.join-head:hover {
  color: #1a8fd1;
}

@media only screen and (max-width: 5061px) {
  .join-head {
    font-size: 24rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 13% !important;
  }
  .join-btn{
    font-size: 10rem;
    margin-left: 42%;
  }
}
@media only screen and (max-width: 4651px) {
  .join-head {
    font-size: 20rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 18% !important;

  }
   .join-btn{
    font-size: 10rem;
    margin-left: 45%;
  }
}
@media only screen and (max-width: 4511px) {
  .join-head {
    font-size: 20rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 18% !important;

  }
   .join-btn{
    font-size: 10rem;
    margin-left: 47%;
  }
}
@media only screen and (max-width: 4474px) {
  .join-head {
    font-size: 20rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 18% !important;

  }
   .join-btn{
    font-size: 10rem;
    margin-left: 45%;
  }
}
@media only screen and (max-width: 4040px) {
  .join-head {
    font-size: 20rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 13% !important;
  }
  
}
@media only screen and (max-width: 4022px) {
  .join-head {
    font-size: 20rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 18% !important;

  }
   .join-btn{
    font-size: 10rem;
    margin-left: 45%;
  }
}
@media only screen and (max-width: 3795px) {
  .join-head {
    font-size: 20rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 13% !important;
  }
}
@media only screen and (max-width: 3751px) {
  .join-head {
    font-size: 20rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 13% !important;
  }
}
@media only screen and (max-width: 3352px) {
  .join-head {
    font-size: 17rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 13% !important;
  }
  .join-btn{
    font-size: 8rem;
    margin-left: 45%;
  }
}
@media only screen and (max-width: 3336px) {
  .join-head {
    font-size: 18rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 13% !important;
  }
}
@media only screen and (max-width: 3202px) {
  
   .join-btn{
    font-size: 7rem;
    margin-left: 45%;
  }
}
@media only screen and (max-width: 3061px) {
  .join-head {
    font-size: 18rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 10% !important;
  }
}
@media only screen and (max-width: 2896px) {
  .join-head {
    font-size: 17rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 10% !important;
  }
  .join-btn{
    font-size: 7rem;
    margin-left: 45%;
  }
}
@media only screen and (max-width: 2892px) {
  .join-head {
    font-size: 15rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 10% !important;
  }
}
@media only screen and (max-width: 2804px) {
  
  .join-btn{
   font-size: 4rem;
   margin-left: 45%;
 }
}
@media only screen and (max-width: 2791px) {
  .join-head {
    font-size: 12rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 18% !important;
  }

}
@media only screen and (max-width: 2742px) {
  
  .join-btn{
   font-size: 4rem;
   margin-left: 47%;
 }
}
@media only screen and (max-width: 2476px) {
  .join-head {
    font-size: 12rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 15% !important;
  }
}
@media only screen and (max-width: 2293px) {
  .join-head {
    font-size: 12rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 13% !important;
  }
}
@media only screen and (max-width: 2185px) {
  .join-head {
    font-size: 12rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 13% !important;
  }
 
}
@media only screen and (max-width: 2022px) {
  .join-head {
    font-size: 12rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 10% !important;
  }
}
@media only screen and (max-width: 1933px) {
  .join-head {
    font-size: 10rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 13% !important;
  }
}
@media only screen and (max-width: 1915px) {
  
  .join-btn{
   font-size: 2rem;
   margin-left: 47%;
 }
}
@media only screen and (max-width: 1834px) {
  
  .join-btn{
   font-size: 2rem;
   margin-left: 49%;
 }
}

@media only screen and (max-width: 1724px) {
  .join-head {
    font-size: 10rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 10% !important;
  }
}
@media only screen and (max-width: 1619px) {
  .join-head {
    font-size: 10rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 8% !important;
  }
}
@media only screen and (max-width: 1569px) {
  .join-head {
    font-size: 9rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 8% !important;
  }
}
@media only screen and (max-width: 1554px) {
  
  .join-btn{
   font-size: 2rem;
   margin-left: 45%;
 }
}
@media only screen and (max-width: 1454px) {
  
  .join-btn{
   font-size: 2rem;
   margin-left: 47%;
 }
}
@media only screen and (max-width: 1409px) {
  .join-head {
    font-size: 9rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 4% !important;
  }
}
@media only screen and (max-width: 1405px) {
  .join-head {
    font-size: 8rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 4% !important;
  }
 
}

@media only screen and (max-width: 1333px) {
  
  .join-btn{
   font-size: 2rem;
   margin-left: 42%;
 }
}
@media only screen and (max-width: 1330px) {
  .join-head {
    font-size: 8rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 4% !important;
  }
}
@media only screen and (max-width: 1187px) {
  .join-head {
    font-size: 7rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 6% !important;
  }
}
@media only screen and (max-width: 1333px) {
  
  .join-btn{
   font-size: 2rem;
   margin-left: 44%;
 }
}
@media only screen and (max-width: 1081px) {
  .join-head {
    font-size: 7rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 5% !important;
  }
}
@media only screen and (max-width: 1052px) {
  .join-head {
    font-size: 5rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 14% !important;
  }
}

@media only screen and (max-width: 1333px) {
  
  .join-btn{
   font-size: 1rem;
   margin-left: 48%;
 }
}
@media only screen and (max-width: 926px) {
  .join-head {
    font-size: 5rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 12% !important;
  }
}
@media only screen and (max-width: 873px) {
  .join-head {
    font-size: 5rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 10% !important;
  }
  .join-btn{
    font-size: 1rem;
    margin-left: 46%;
  }
}
@media only screen and (max-width: 810px) {
  
  .join-btn{
   font-size: 1rem;
   margin-left: 48%;
 }
}
@media only screen and (max-width: 805px) {
  .join-head {
    font-size: 4rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 12% !important;
  }
}
@media only screen and (max-width: 791px) {
  
  .join-btn{
   font-size: 1rem;
   margin-left: 45%;
 }
}
@media only screen and (max-width: 717px) {
  
  .join-btn{
   font-size: 1rem;
   margin-left: 47%;
 }
}
@media only screen and (max-width: 673px) {
  .join-head {
    font-size: 4rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 10% !important;
  }
}
@media only screen and (max-width: 645px) {
  .join-head {
    font-size: 3.5rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 10% !important;
  }
  .desktop-video-join {
    display: none;
  }
  .mobile-video-join {
    display: block;
  }
}

@media only screen and (max-width: 566px) {
  .join-head {
    font-size: 3rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 10% !important;
  }
}
@media only screen and (max-width: 555px) {
  
  .join-btn{
   font-size: 1rem;
   margin-left: 45%;
 }
}
@media only screen and (max-width: 490px) {
  .join-head {
    font-size: 3rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 9% !important;
  }
}
@media only screen and (max-width: 476px) {
  .join-head {
    font-size: 2.5rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 11% !important;
  }
}
@media only screen and (max-width: 475px) {
  
  .join-btn{
   font-size: 1rem;
   margin-left: 43%;
 }
}
@media only screen and (max-width: 425px) {
  
  .join-btn{
   font-size: 1rem;
   margin-left: 45%;
 }
}
@media only screen and (max-width: 409px) {
  .join-head {
    font-size: 2rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 12% !important;
  }
}
@media only screen and (max-width: 407px) {
  
  .join-btn{
   font-size: 0.8rem;
   margin-left: 42%;
 }
}
@media only screen and (max-width: 375px) {
  
  .join-btn{
   font-size: 0.8rem;
   margin-left: 45%;
 }
}
@media only screen and (max-width: 330px) {
  .join-head {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 15% !important;
  }
}
@media only screen and (max-width: 261px) {
  .join-head {
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-left: 15% !important;
  }
}
