.ai-head {
  font-size: 3rem !important;
  text-align: left;
  padding-top: 5%;
  color: #1a8fd1;
}
.ai-text {
  text-align: left;
  color: #1a8fd1;
}

@media only screen and (max-width: 800px) {
  .ai-head {
    font-size: 2rem !important;
    text-align: center;
    padding-top: 5%;
    font-weight: 600 !important;
  }
  .ai-text {
    font-size: 0.8rem !important;
  }
}
@media only screen and (max-width: 550px) {
  .ai-head {
    font-size: 2rem !important;
    text-align: center;
    padding-top: 5%;
    font-weight: 600 !important;
  }
  .ai-text {
    font-size: 0.8rem !important;
    padding: 2%;
  }
  .ai-text-second {
    margin-top: -5%;
  }
}
@media only screen and (max-width: 480px) {
  .ai-head {
    font-size: 1.5rem !important;
    text-align: center;
    padding-top: 5%;
    font-weight: 600 !important;
  }
  .ai-text {
    font-size: 0.7rem !important;
    padding: 2%;
  }
  .ai-text-second {
    margin-top: -5%;
  }
}

@media only screen and (max-width: 417px) {
  .ai-head {
    font-size: 1.5rem !important;
    text-align: center;
    padding-top: 5%;
    font-weight: 600 !important;
  }
  .ai-text {
    font-size: 0.5rem !important;
    padding: 2%;
  }
  .ai-text-second {
    margin-top: -5%;
  }
}

@media only screen and (max-width: 365px) {
  .ai-head {
    font-size: 1.2rem !important;
    text-align: center;
    padding-top: 5%;
    font-weight: 600 !important;
  }
  .ai-text {
    font-size: 0.5rem !important;
    padding: 2%;
  }
  .ai-text-second {
    margin-top: -5%;
  }
}

@media only screen and (max-width: 290px) {
  .ai-head {
    font-size: 0.8rem !important;
    text-align: center;
    padding-top: 5%;
    font-weight: 600 !important;
  }
  .ai-text {
    font-size: 0.4rem !important;
    padding: 2%;
  }
  .ai-text-second {
    margin-top: -5%;
  }
}

.cube-container {
  font-size: 10vw;
  transform-style: preserve-3d;
  transform: rotateX(-35deg) rotateY(45deg);
  animation: spin 2s steps(1) infinite;
  color: #1a8fd1;
  margin-top: 10%;
}
@keyframes spin {
  0%,
  100% {
    transform: rotateX(-35deg) rotateY(45deg);
  }
  50% {
    transform: rotateX(-35deg) rotateY(-45deg);
  }
}

.cube {
  margin: auto;
  width: 1em;
  height: 1em;
  position: relative;
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.side {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 2px;
  box-sizing: border-box;
  background: #fff;
  transform-style: preserve-3d;
}

.front {
  transform: rotateY(0deg) translateZ(0.5em);
}

.back {
  transform: rotateY(180deg) translateZ(0.5em);
}

.right {
  transform: rotateY(90deg) translateZ(0.5em);
}

.left {
  transform: rotateY(-90deg) translateZ(0.5em);
}

.top {
  transform: rotateX(90deg) translateZ(0.5em);
}

.bottom {
  transform: rotateX(-90deg) translateZ(0.5em);
}

.consumed {
  font-size: 2em;
  animation: scale-down 1s cubic-bezier(0.52, 0.08, 0.28, 0.94) infinite;
}
@keyframes scale-down {
  90%,
  100% {
    transform: scale3d(0.5, 0.5, 0.5);
  }
}

.consumer {
  position: relative;
  font-size: 2em;
  z-index: 2;
  animation: consume-in 1s cubic-bezier(0.52, 0.08, 0.28, 0.94) infinite;
}
@keyframes consume-in {
  0% {
    transform: translateZ(-3em);
  }
  90% {
    transform: translateZ(0em);
  }
}
.consumer .front {
  transform: rotateY(0deg) translateZ(0.5em) rotateX(0deg);
  transform-origin: top center;
  animation: consume-flap 1s cubic-bezier(0.52, 0.08, 0.28, 0.94) infinite;
}
@keyframes consume-flap {
  0%,
  10% {
    transform: rotateY(0deg) translateZ(0.5em) rotateX(280deg);
  }
  90% {
    transform: rotateY(0deg) translateZ(0.5em) rotateX(0deg);
  }
}
