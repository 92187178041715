.image-1 {
  position: relative;
  z-index: 10000;
  animation: image1 5s infinite alternate-reverse;
}

@keyframes image1 {
  0% {
    margin-top: -1%;
  }
  100% {
    margin-top: 1%;
  }
}

.mobile-service {
  animation: mobileImage 5s infinite alternate-reverse;
  position: absolute;
}

@keyframes mobileImage {
  0% {
    margin-top: -3%;
  }
  100% {
    margin-top: 3%;
  }
}

.image-2 {
  z-index: 10000;
  position: relative;

  animation: image2 5s infinite alternate-reverse;
}

@keyframes image2 {
  0% {
    margin-left: -5%;
  }
  100% {
    margin-left: 5%;
  }
}

.image-3 {
  z-index: 10000;
  position: relative;

  animation: image3 10s infinite alternate-reverse;
}

@keyframes image3 {
  0% {
    margin-left: -7%;
  }
  100% {
    margin-left: 7%;
  }
}

.image-4 {
  z-index: 10000;
  position: relative;

  animation: image4 12s infinite alternate-reverse;
}

@keyframes image4 {
  0% {
    margin-top: -6%;
  }
  100% {
    margin-top: 6%;
  }
}

.image-5 {
  z-index: 10000;
  position: relative;
  animation: image5 6.4s infinite alternate-reverse;
}

@keyframes image5 {
  0% {
    margin-top: -8%;
  }
  100% {
    margin-top: 8%;
  }
}

.image-6 {
  z-index: 10000;
  position: relative;
  animation: image6 7.4s infinite alternate-reverse;
}

@keyframes image6 {
  0% {
    margin-top: -8%;
  }
  100% {
    margin-top: 8%;
  }
}

.image-upper {
  margin-top: -150px !important;
}

.middle-image {
  width: 33%;
  margin-top: -10%;
  margin-left: 2%;
  position: absolute;
}

.bottom-images {
  margin-top: 12% !important;
  margin-left: 7% !important;
  margin-bottom: 10%;
}

.service-header {
  text-align: center;
  font-size: 2.5rem !important;
  padding-top: 2%;
  color: rgb(255, 255, 255);
}

.icon-service {
  font-size: 3rem !important;
  color: #fff;
}

.tab-view-service {
  display: none;
}

.service-head {
  font-size: 2rem !important;
  color: #dfddddeb;
}

.mobile-view-service {
  display: none;
}

@media only screen and (max-width: 5061px) {
  .middle-image {
    width: 10%;
    margin-top: -3%;
    margin-left: 2.5%;
    position: absolute;
  }
}
@media only screen and (max-width: 3795px) {
  .middle-image {
    width: 13%;
    margin-top: -3%;
    margin-left: 2%;
    position: absolute;
  }
}
@media only screen and (max-width: 3751px) {
  .middle-image {
    width: 13%;
    margin-top: -3%;
    margin-left: 2%;
    position: absolute;
  }
}
@media only screen and (max-width: 2791px) {
  .middle-image {
    width: 16%;
    margin-top: -5%;
    margin-left: 3%;
    position: absolute;
  }
}
@media only screen and (max-width: 2695px) {
  .middle-image {
    width: 16.8%;
    margin-top: -5%;
    margin-left: 3%;
    position: absolute;
  }
}
@media only screen and (max-width: 2474px) {
  .middle-image {
    width: 20%;
    margin-top: -6%;
    margin-left: 3%;
    position: absolute;
  }
}

@media only screen and (max-width: 2254px) {
  .middle-image {
    width: 18%;
    margin-top: -5%;
    margin-left: 3%;
    position: absolute;
  }
}
@media only screen and (max-width: 2250px) {
  .middle-image {
    width: 19%;
    margin-top: -5%;
    margin-left: 3%;
    position: absolute;
  }
}
@media only screen and (max-width: 2130px) {
  .middle-image {
    width: 20%;
    margin-top: -5%;
    margin-left: 3%;
    position: absolute;
  }
}
@media only screen and (max-width: 2023px) {
  .middle-image {
    width: 21%;
    margin-top: -5%;
    margin-left: 3%;
    position: absolute;
  }
}
@media only screen and (max-width: 1930px) {
  .middle-image {
    width: 22%;
    margin-top: -5%;
    margin-left: 3%;
    position: absolute;
  }
}
@media only screen and (max-width: 1929px) {
  .middle-image {
    width: 23%;
    margin-top: -6%;
    margin-left: 3%;
    position: absolute;
  }
}
@media only screen and (max-width: 1805px) {
  .middle-image {
    width: 23%;
    margin-top: -6%;
    margin-left: 3%;
    position: absolute;
  }
}
@media only screen and (max-width: 1756px) {
  .middle-image {
    width: 24%;
    margin-top: -6%;
    margin-left: 3%;
    position: absolute;
  }
}
@media only screen and (max-width: 1719px) {
  .middle-image {
    width: 24%;
    margin-top: -6%;
    margin-left: 3%;
    position: absolute;
  }
}
@media only screen and (max-width: 1665px) {
  .middle-image {
    width: 25%;
    margin-top: -6%;
    margin-left: 4%;
    position: absolute;
  }
}
@media only screen and (max-width: 1646px) {
  .middle-image {
    width: 27%;
    margin-top: -9%;
    margin-left: 3%;
    position: absolute;
  }
}
@media only screen and (max-width: 1546px) {
  .middle-image {
    width: 27%;
    margin-top: -8%;
    margin-left: 3%;
    position: absolute;
  }
}

@media only screen and (max-width: 1513px) {
  .middle-image {
    width: 28%;
    margin-top: -8%;
    margin-left: 4%;
    position: absolute;
  }
}
@media only screen and (max-width: 1434px) {
  .middle-image {
    width: 28%;
    margin-top: -8%;
    margin-left: 5%;
    position: absolute;
  }
}

@media only screen and (max-width: 1349px) {
  .image-upper {
    margin-left: -15% !important;
  }
  .middle-image {
    width: 33%;
  }
}
@media only screen and (max-width: 1293px) {
  .image-upper {
    margin-left: -15% !important;
  }
  .middle-image {
    width: 36%;
    margin-top: -10%;
  }
  .bottom-images {
    margin-top: 10% !important;
  }
}
@media only screen and (max-width: 1160px) {
  .mobile-view-service {
    display: block;
  }
  .desktop-view-service {
    display: none;
  }
  .middle-image {
    width: 40%;
    margin-top: -12%;
  }
  .bottom-images {
    margin-top: 2% !important;
  }
}
@media only screen and (max-width: 1008px) {
  .image-upper {
    margin-left: -20% !important;
  }
  .middle-image {
    width: 47%;
  }
  .bottom-images {
    margin-top: 10% !important;
  }
}
@media only screen and (max-width: 836px) {
  .image-upper {
    margin-left: -20% !important;
  }
  .middle-image {
    width: 47%;
  }
  .bottom-images {
    margin-top: 10% !important;
  }
  .service-header {
    font-size: 2rem !important;
  }
}
@media only screen and (max-width: 640px) {
  .image-upper {
    margin-left: -20% !important;
  }
  .middle-image {
    width: 47%;
  }
  .bottom-images {
    margin-top: 10% !important;
  }
  .service-header {
    font-size: 2rem !important;
  }
  .icon-service {
    font-size: 2rem !important;
    color: #fff;
  }
}
@media only screen and (max-width: 602px) {
  .image-upper {
    margin-left: -20% !important;
  }
  .middle-image {
    width: 47%;
  }
  .bottom-images {
    margin-top: 10% !important;
  }
  .service-header {
    font-size: 1.5rem !important;
  }
  .icon-service {
    font-size: 2rem !important;
    color: #fff;
  }
  .service-head {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
    color: #dfddddeb;
  }
}

@media only screen and (max-width: 590px) {
  .image-upper {
    margin-left: -20% !important;
  }
  .middle-image {
    width: 47%;
  }
  .bottom-images {
    margin-top: 10% !important;
  }
  .service-header {
    font-size: 1.5rem !important;
  }
  .icon-service {
    font-size: 2rem !important;
    color: #fff;
  }
  .service-head {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
    color: #dfddddeb;
  }
  .mobile-view-service {
    display: block;
  }
  .tab-view-service {
    display: none;
  }
}

@media only screen and (max-width: 435px) {
  .image-upper {
    margin-left: -10% !important;
  }
  .middle-image {
    width: 47%;
    margin-left: 10%;
  }
  .bottom-images {
    margin-top: 10% !important;
  }
  .service-header {
    font-size: 1.5rem !important;
  }
  .icon-service {
    font-size: 2rem !important;
    color: #fff;
  }
  .service-head {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
    color: #dfddddeb;
  }
  .mobile-view-service {
    display: block;
  }
  .tab-view-service {
    display: none;
  }
}

@media only screen and (max-width: 412px) {
  .image-upper {
    margin-left: -20% !important;
  }
  .middle-image {
    width: 47%;
  }
  .bottom-images {
    margin-top: 10% !important;
  }
  .service-header {
    font-size: 1.2rem !important;
    font-weight: 600 !important;
  }
  .icon-service {
    font-size: 2rem !important;
    color: #fff;
  }
  .service-head {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
    color: #dfddddeb;
  }
  .mobile-view-service {
    display: block;
  }
  .tab-view-service {
    display: none;
  }
}

@media only screen and (max-width: 405px) {
  .image-upper {
    margin-left: -20% !important;
  }
  .middle-image {
    width: 47%;
  }
  .bottom-images {
    margin-top: 10% !important;
  }
  .service-header {
    font-size: 1rem !important;
    font-weight: 600 !important;
  }
  .icon-service {
    font-size: 2rem !important;
    color: #fff;
  }
  .service-head {
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #dfddddeb;
  }
  .mobile-view-service {
    display: block;
  }
  .tab-view-service {
    display: none;
  }
}
@media only screen and (max-width: 387px) {
  .image-upper {
    margin-left: -20% !important;
  }
  .middle-image {
    width: 47%;
  }
  .bottom-images {
    margin-top: 8% !important;
  }
  .service-header {
    font-size: 1rem !important;
    font-weight: 600 !important;
  }
  .icon-service {
    font-size: 2rem !important;
    color: #fff;
  }
  .service-head {
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #dfddddeb;
  }
  .mobile-view-service {
    display: block;
  }
  .tab-view-service {
    display: none;
  }
}

@media only screen and (max-width: 332px) {
  .image-upper {
    margin-left: -20% !important;
  }
  .middle-image {
    width: 47%;
  }
  .bottom-images {
    margin-top: 10% !important;
  }
  .service-header {
    font-size: 0.8rem !important;
    font-weight: 600 !important;
  }
  .icon-service {
    font-size: 2rem !important;
    color: #fff;
  }
  .service-head {
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #dfddddeb;
  }
  .mobile-view-service {
    display: block;
  }
  .tab-view-service {
    display: none;
  }
}

@media only screen and (max-width: 319px) {
  .image-upper {
    margin-left: -20% !important;
  }
  .middle-image {
    width: 50%;
  }
  .bottom-images {
    margin-top: 10% !important;
  }
  .service-header {
    font-size: 0.8rem !important;
    font-weight: 600 !important;
  }
  .icon-service {
    font-size: 2rem !important;
    color: #fff;
  }
  .service-head {
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #dfddddeb;
  }
  .mobile-view-service {
    display: block;
  }
  .tab-view-service {
    display: none;
  }
}
