.post_featured {
  margin: auto !important;
  display: block !important;
}
.post_featured:hover {
  transform: scale(1.05, 1.05);
}
.sc_team_short .post_featured img {
  -webkit-transition: -webkit-transform 0.3s ease;
  -ms-transition: -ms-transform 0.3s ease;
  transition: transform 0.3s ease;
}

.sc_team_short .trx_addons_hover_team {
  padding: 15px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  z-index: 99;
}
.trx_addons_hover:hover .trx_addons_hover_text,
.trx_addons_hover:hover .trx_addons_hover_info {
  -webkit-transition: all 0.3s 0.1s ease-out;
  -ms-transition: all 0.3s 0.1s ease-out;
  transition: all 0.3s 0.1s ease-out;
}
.sc_team_short
  .trx_addons_hover_style_info_anim:hover
  .sc_team_item_socials
  .social_item:nth-child(1) {
  transition-delay: 0.1s;
}
.sc_team_short
  .trx_addons_hover_style_info_anim:hover
  .sc_team_item_socials
  .social_item {
  opacity: 1;
  top: 0;
}
.sc_team_short .trx_addons_hover_team .sc_team_item_socials .social_item {
  opacity: 0;
  transition-property: all;
  transition-duration: 0.3s;
  top: 20px;
  position: relative;
  margin: 3px !important;
  display: inline-block;
  vertical-align: top;
  line-height: 1;
}
.sc_team_short
  .trx_addons_hover_team
  .sc_team_item_socials
  .social_item
  .social_icon {
  color: #000;
  background-color: #fff;
  border: none;
  font-size: 16px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  -webkit-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.sc_team_short .post_featured img {
  -webkit-transition: -webkit-transform 0.3s ease;
  -ms-transition: -ms-transform 0.3s ease;
  transition: transform 0.3s ease;
}
.post_featured[class*="hover_"],
.post_featured[class*="hover_"] * {
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.post_featured:not(.post_featured_bg)[class*="hover_"] {
  display: inline-block;
  vertical-align: top;
}
.post_featured[class*="hover_"] {
  position: relative;
  -webkit-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.sc_team_short
  .trx_addons_hover_style_info_anim:hover
  .sc_team_item_socials
  .social_item:nth-child(1) {
  transition-delay: 0.1s;
}
.sc_team_short
  .trx_addons_hover_style_info_anim:hover
  .sc_team_item_socials
  .social_item {
  opacity: 1;
  top: 0;
}
.sc_team_short .trx_addons_hover_team .sc_team_item_socials .social_item {
  opacity: 0;
  transition-property: all;
  transition-duration: 0.3s;
  top: 20px;
  position: relative;
  margin: 3px !important;
  display: inline-block;
  vertical-align: top;
  line-height: 1;
}

.blob {
  background: #fff;
  border-radius: 50%;

  rotate: 40deg;
  margin: 10px;
  height: 80px;
  width: 80px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

.blob.white {
  background: white;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse-white 2s infinite;
}

.blob .white {
  padding: 1.3%;
}

.linkdin-icon-team {
  background-color: rgb(26, 143, 209);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 3px;
  color: rgb(255, 255, 255);
  display: block;
  margin: -6% auto auto !important;
  z-index: 10000;
  position: relative;
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.team-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  margin: auto;
  display: block;
}

.social-team-icon {
  background-color: #1a8fd1;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 3px;
  text-align: center;
  color: #fff;
}

.desktop-team {
  display: block;
}
.mobile-team {
  display: none;
}

.mobile-about-content {
  display: none;
}
.desktop-about-content {
  display: block;
}
.mobile-about-play {
  display: none;
}
.desktop-about-play {
  display: block;
}
.card-head {
  font-weight: 400 !important;
  font-size: 2rem !important;
}
.card-head2 {
  font-weight: 600 !important;
  font-size: 32px !important;
  margin-bottom: 30px;
}
.card-text {
  font-weight: 500;
  font-size: 20px !important;
  color: #afafaf;
}

@media only screen and (max-width: 5061px) {
  .team-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
  }
  .social-team-icon {
    height: 30px;
    width: 30px;
    padding: 3px;
  }
  .blob.white {
    padding: 0.4%;
  }
  .linkdin-icon-team {
    margin: -2% auto auto !important;
  }
}
@media only screen and (max-width: 3214px) {
  .team-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
  }
  .social-team-icon {
    height: 30px;
    width: 30px;
    padding: 3px;
  }
  .blob.white {
    padding: 0.4%;
  }
  .linkdin-icon-team {
    margin: -4% auto auto !important;
  }
}
@media only screen and (max-width: 3214px) {
  .team-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
  }
  .social-team-icon {
    height: 30px;
    width: 30px;
    padding: 3px;
  }
  .blob.white {
    padding: 0.4%;
  }
  .linkdin-icon-team {
    margin: -6% auto auto !important;
  }
}
@media only screen and (max-width: 1336px) {
  .team-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
  }
  .social-team-icon {
    height: 30px;
    width: 30px;
    padding: 3px;
  }
}
@media only screen and (max-width: 1128px) {
  .team-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
  }
  .social-team-icon {
    height: 25px;
    width: 25px;
    padding: 1px;
    font-size: 0.6rem !important;
  }
}
@media only screen and (max-width: 965px) {
  .mobile-about-play {
    display: block;
  }
  .desktop-about-play {
    display: none;
  }
}
@media only screen and (max-width: 910px) {
  .team-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
  }
  .social-team-icon {
    height: 30px;
    width: 30px;
    padding: 3px;
  }
  section {
    overflow: hidden;
  }
  .desktop-team {
    display: none;
  }
  .mobile-team {
    display: block;
  }
  .mobile-about-content {
    display: block;
  }
  .desktop-about-content {
    display: none;
  }
}
@media only screen and (max-width: 651px) {
  .card-head {
    font-weight: 400 !important;
    font-size: 1.5rem !important;
  }
  .card-head2 {
    font-weight: 600 !important;
    font-size: 1.5rem !important;
    margin-bottom: 30px;
  }
  .card-text {
    font-weight: 500;
    font-size: 1rem !important;
    color: #afafaf;
  }
}
@media only screen and (max-width: 552px) {
  .card-head {
    font-weight: 400 !important;
    font-size: 1.5rem !important;
  }
  .card-head2 {
    font-weight: 600 !important;
    font-size: 1.5rem !important;
    margin-bottom: 30px;
  }
  .card-text {
    font-weight: 500;
    font-size: 1rem !important;
    color: #afafaf;
  }
  .client-name-about {
    margin-left: 20%;
  }
}
@media only screen and (max-width: 450px) {
  .card-head {
    font-weight: 400 !important;
    font-size: 1.5rem !important;
  }
  .card-head2 {
    font-weight: 600 !important;
    font-size: 1.5rem !important;
    margin-bottom: 30px;
  }
  .card-text {
    font-weight: 500;
    font-size: 1rem !important;
    color: #afafaf;
  }
  .client-name-about {
    margin-left: 80% !important;
  }
}
@media only screen and (max-width: 425px) {
  .card-head {
    font-weight: 400 !important;
    font-size: 1rem !important;
  }
  .card-head2 {
    font-weight: 600 !important;
    font-size: 1rem !important;
    margin-bottom: 30px;
  }
  .card-text {
    font-weight: 500;
    font-size: 0.8rem !important;
    color: #afafaf;
  }
}
